<template>
  <div>
    <div class="float-center mt-2 mb-2 table-title">
      <strong>
        {{
          $getVisibleNames(
            "teaching.matter",
            true,
            "Asignaturas Base"
          ).toUpperCase()
        }}
      </strong>
      <InfoTooltip
        :tooltip_text="`Estas son las ${$getVisibleNames(
          'teaching.matter',
          true,
          'Asignaturas Base'
        )} que pueden ser utilizadas en varios Perfiles de Egreso, replicando sus resultados de aprendizaje.`"
      >
      </InfoTooltip>
    </div>

    <div
      v-if="
        allows_crud &&
        ((user_position &&
          [1, 2].includes(user_position.position) &&
          [1, 2].includes(user.groups[0])) ||
          user.is_superuser)
      "
      class="float-left"
    >
      <!-- <b-button
        size="sm"
        class="mt-2 mr-1"
        variant="secondary"
        @click="$bvModal.show(`new-old-matter-modal-1`)"
        ><b-icon-plus></b-icon-plus>Agregar
        {{
          $getVisibleNames("teaching.matter", false, "Asignatura Base")
        }}</b-button
      > -->
      <ButtonMedium
        :tooltip_text="`Agregar ${$getVisibleNames(
          'teaching.matter',
          false,
          'Asignatura Base'
        )}`"
        :text_button="`Agregar`"
        :click_button="() => $bvModal.show(`new-old-matter-modal-1`)"
        :custom_class="`mr-1 mt-2 ml-3`"
        :icon="'plus'"
        :variant="'primary'"
      >
      </ButtonMedium>
    </div>
    <div>
      <NewGenericBTable
        :key="generic_table_key"
        :fields="fields_old_matter"
        :pre_current_page="current_page"
        :pre_multiple_search="current_tag"
        :url="'/teaching/alt-matter/'"
        @current_page="currentPage"
        @current_tag="currentTag"
      >
        <template #cell(name)="row">
          <b-link
            v-if="user && user.is_superuser"
            :to="{
              name: 'OldMatterProgramView',
              params: {
                matter_id: row.item.id,
              },
            }"
          >
            {{ row.item.name }}
          </b-link>
          <span>
            {{ row.item.name }}
          </span>
        </template>
        <template #cell(regime)="row">
          <span>{{ getRegimen(row.item.regime) }}</span>
        </template>
        <template #cell(formation_area)="row">
          <span>{{ getFormationArea(row.item.formation_area) }}</span>
        </template>
        <template #cell(study_units)="row">
          <b-button
            :id="`popover-button-matter-${row.item.id}`"
            size="sm"
            style="max-height: 30px"
          >
            <b-badge class="badge-ra" variant="light">{{
              row.item.study_units.length
            }}</b-badge>
          </b-button>
          <b-popover
            :target="`popover-button-matter-${row.item.id}`"
            placement="lefttop"
            triggers="focus"
          >
            <template #title>{{
              $getVisibleNames("teaching.ramicro", false, "RA Micro")
            }}</template>
            <div
              class="popover-container"
              v-if="row.item.study_units.length > 0"
            >
              <li
                v-for="(item, index) in row.item.study_unit_full_sentences"
                :key="row.item.id + '-' + index"
              >
                {{ item[1] }}
              </li>
            </div>
            <div v-else>
              No cuenta con
              {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }}
            </div>
          </b-popover>
        </template>
        <template #cell(career_names)="row">
          <b-button
            :id="`popover-button-career_names-${row.item.id}`"
            size="sm"
            style="max-height: 30px"
          >
            <b-badge class="badge-ra" variant="light">{{
              row.item.career_names.length
            }}</b-badge>
          </b-button>
          <b-popover
            :target="`popover-button-career_names-${row.item.id}`"
            placement="lefttop"
            triggers="focus"
          >
            <template #title>{{
              $getVisibleNames("mesh.career", false, "Programa")
            }}</template>
            <div
              class="popover-container"
              v-if="row.item.career_names.length > 0"
            >
              <li
                v-for="(item, index) in row.item.career_names"
                :key="row.item.id + '-career-' + index"
              >
                {{ item[1] }}
              </li>
            </div>
            <div v-else>
              No cuenta con
              {{ $getVisibleNames("mesh.career", false, "Programa") }}
            </div>
          </b-popover>
        </template>
        <template #cell(actions)="row">
          <b-button
            v-if="allows_crud && isClosedMatter(row.item)"
            size="sm"
            variant="none"
            v-b-tooltip.v-secondary.noninteractive="
              `Cambiar el estado del ${$getVisibleNames(
                'teaching.matter',
                false,
                'Asignatura Base'
              )}`
            "
            @click="changeStateMatter(row.item)"
          >
            <template v-if="row.item.is_closed">
              <b-icon icon="lock-fill" scale="1"></b-icon>
            </template>
            <template v-else>
              <b-icon icon="unlock-fill" scale="1"></b-icon>
            </template>
          </b-button>
          <ButtonSmall
            v-can="'teaching.change_matter'"
            v-if="allows_crud && !row.item.is_closed"
            :tooltip_text="`Editar ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`"
            :click_button="() => MatterNameModal(row.item)"
            :class="``"
            :icon="'square'"
          ></ButtonSmall>
          <!-- <button-edit
            v-can="'teaching.change_matter'"
            v-if="allows_crud && !row.item.is_closed"
            @click="MatterNameModal(row.item)"
            v-b-tooltip.v-secondary.auto.noninteractive="{
              customClass: 'custom-tooltip',
            }"
            :title="`Editar ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`"
          ></button-edit> -->
          <b-modal
            :id="`modal-matter-name-${row.item.id}`"
            hide-footer
            :title="`Editar ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`"
            size="lg"
          >
            <OldMatterForm
              :OldMatter="row.item"
              @updated="updateOldMatter"
              :show_delete_button="true"
              @delete="removeOldMatter"
            >
            </OldMatterForm>
          </b-modal>
        </template>
      </NewGenericBTable>
    </div>
    <b-modal
      :id="`new-old-matter-modal-1`"
      hide-footer
      :title="`Crear ${$getVisibleNames(
        'teaching.matter',
        false,
        'Asignatura Base'
      )}`"
      size="xl"
    >
      <OldMatterForm @created="createdOldMatter"> </OldMatterForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";
export default {
  name: "OldMatterAltList",
  components: {
    NewGenericBTable: () => import("@/components/reusable/NewGenericBTable"),
    OldMatterForm: () => import("@/components/mesh/Matrix2/OldMatterForm"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      generic_table_key: 0,
      current_page: 1,
      current_tag: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      matters: names.MATTERS,
      regimes: names.REGIMES,
      formationAreas: names.FORMATION_AREAS,
    }),
    fields_old_matter() {
      return [
        {
          key: "name",
          label: this.$getVisibleNames(
            "teaching.matter",
            true,
            "Asignaturas Base"
          ),
          sortable: true,
          class: "text-left",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            return value.toUpperCase();
          },
        },
        {
          key: "code",
          label: "Código",
          sortable: true,
          class: "text-center",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "weeks",
          label: "Semanas",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "formation_area",
          label: "Área de Formación",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "study_units",
          label: this.$getVisibleNames("teaching.ramicro", false, "RA Micro"),
          //   sortable: true,
          class: "text-center",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "career_names",
          label: this.$getVisibleNames("mesh.career", true, "Programa"),
          //   sortable: true,
          class: "text-center",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },

          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    isClosedMatter(old_matter) {
      if (
        this.user.is_superuser ||
        (this.$hasObjectPermission(
          "special.close_base_matter",
          "special",
          old_matter.id
        ) &&
          !old_matter.is_closed) ||
        (this.$hasObjectPermission(
          "special.open_base_matter",
          "special",
          old_matter.id
        ) &&
          old_matter.is_closed)
        // ||(this.user_access && this.user_access.position == 1)
      ) {
        return true;
      } else return false;
    },
    getRegimen(id) {
      const regimen = this.regimes.find((x) => x.id == id);
      return regimen ? regimen.name : "";
    },
    getFormationArea(id) {
      const formation_area = this.formationAreas.find((x) => x.id == id);
      return formation_area ? formation_area.name : "N/A";
    },
    MatterNameModal(row_item) {
      this.$root.$emit("bv::show::modal", `modal-matter-name-${row_item.id}`);
    },
    changeStateMatter(old_matter) {
      const payload = {
        matter_id: old_matter.id,
        item: {
          is_closed: !old_matter.is_closed,
        },
      };
      this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
        this.generic_table_key++;
      });
    },
    removeOldMatter(old_matter) {
      this.$bvModal.hide(`modal-matter-name-${old_matter.id}`);
      this.generic_table_key++;
    },
    updateOldMatter(response) {
      this.$bvModal.hide(`modal-matter-name-${response.id}`);
      this.generic_table_key++;
    },
    createdOldMatter() {
      this.$bvModal.hide(`new-old-matter-modal-1`);
      this.generic_table_key++;
    },
    currentPage(current_page) {
      this.current_page = current_page;
    },
    currentTag(current_tag) {
      this.current_tag = current_tag;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.badge-ra {
  display: flex;
  padding: 1px 4px;
  margin: 0.1em 0em;
  font-size: var(--thirth-font-size);
  background-color: transparent;
  color: white;
}
.popover-container {
  overflow: auto;
  max-height: 400px;
}

.popover-container::-webkit-scrollbar {
  width: 6px;
}
.popover-container::-webkit-scrollbar-track {
  background: #e2e2e2;
}

.popover-container::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 8px;
}

.popover-container::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
</style>